import React from 'react';

import { Trans } from 'react-i18next';

import { isMobileDevice } from '../../Helpers/DeviceHelpers'

import TwLogo from '../../resources/images/logo.png'
import BorderlineLogo from '../../resources/images/borderline-logo-hd.png'

import FooterLight from './FooterLight'
import JoinCommunity from './JoinCommunity';

class Footer extends React.Component {

    renderFooter() {
        return (
            <div className="footer">
                <div className="footer-column footer-left">
                    <img src={TwLogo} className="footer-tw-logo" alt="Trollworkshop" />
                    <span className="copyright"><Trans>FOOTER_COPYRIGHT</Trans></span>
                </div>
                <div className="footer-logo-container">
                    <a href="http://borderlineeditions.com/" target="_blank">
                        <img src={BorderlineLogo} className="footer-borderline-logo" alt="Borderline" />
                    </a>
                </div>
                <div className="footer-column footer-right">
                    <JoinCommunity />
                </div>
            </div>
        );
    }

    render() {
        const isMobile = isMobileDevice();

        if (isMobile)
            return  <FooterLight />
        else
            return this.renderFooter();
    }
}

export default Footer;