import React from 'react'
import { Helmet } from "react-helmet";

import { Trans } from 'react-i18next';

import Form from '../../components/form/Form'

import '../../components/form/Form.css';

import { AccountService } from '../../../services/AccountService';
import handleError from '../../ErrorHelper';

class ResetPassword extends React.Component {

    _accountService = new AccountService();

    constructor(props) {
        super(props);

        this.state = 
        {
            errorMsg: null,
            success: false,
            isWorking: false,
            email: '',
        };
    }

    handleSubmit = async (fields) => {
        console.log(fields);
 
        try {
            this.setState({
                isWorking: true,
                errorMsg: '',
            });

            const email = fields['email'].value;

            await this._accountService.sendPasswordResetCode(email);
            console.log("Email sent with sucess to " + email);

            this.setState(
            {
                success: true,
                email: email,
            });

        } catch (ex) {
            this.setState({errorMsg: handleError(ex, "Failed to send code to reset password.")});
        } finally {
            this.setState({isWorking: false});
        }   
    }

    renderForm() {

        const fields = [
            {
                id: 'email',
                placeholder: 'RESETPASSWORDVIEW_INPUT_EMAIL',
                type: 'email',
                options: {
                    required: true,
                    email: true,
                },
                initialValue: '',
            }
        ];

        if (!this.state.success) {
            return (
                <div>
                    <h1><Trans>RESETPASSWORDVIEW_TITLE</Trans></h1>

                    <div style={{marginTop:'30px'}}>
                        <p className='text-left'>
                            <Trans>RESETPASSWORDVIEW_HEADER</Trans>
                        </p>
                    </div>

                    <div style={{marginTop:'-20px'}}>
                        <div>
                            <Form 
                                fields={fields} 
                                submitText='RESETPASSWORDVIEW_BUTTON_SENDEMAIL'
                                onSubmit={this.handleSubmit} 
                                working={this.state.isWorking} />
                            <br/>
                            <span className='error-msg-style'>{this.state.errorMsg}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderSuccess() {
        if (this.state.success) {
            return (
                <div>
                    <h1><Trans>RESETPASSWORDVIEW_TITLE_EMAILSENT</Trans></h1>

                    <div style={{marginTop:'30px'}}>
                        <p className="text-left ">
                            <Trans>RESETPASSWORDVIEW_TEXT_EMAILSENT_PART1</Trans>
                            <span className='font-weight-bold'> {this.state.email}</span>. 
                            <Trans>RESETPASSWORDVIEW_TEXT_EMAILSENT_PART2</Trans>
                        </p>
                        <p className="text-left">
                        <Trans>RESETPASSWORDVIEW_TEXT_EMAILSENT_PART3</Trans>
                        </p>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Réinitialiser votre mot de passe</title>
                </Helmet>
                {this.renderForm()}
                {this.renderSuccess()}
            </div>
        )
    }

}

export default ResetPassword;