import React from 'react';

import { Trans } from 'react-i18next';

import TwLogo from '../../resources/images/logo.png'
import BorderlineLogo from '../../resources/images/borderline-logo-hd.png'

class FooterLight extends React.Component {
    render() {
      return (
        <div className="footer-light">
            <div className="footer-logo-container">
                <img src={TwLogo} className="footer-tw-logo" alt="Trollworkshop" />
                <a href="http://borderlineeditions.com/" target="_blank">
                    <img src={BorderlineLogo} className="footer-borderline-logo" alt="Borderline" />
                </a>
            </div>
            <div className="footer-light-copyright">
                <span className="copyright"><Trans>FOOTER_COPYRIGHT</Trans></span>
            </div>
        </div>
        );
    }
}

export default FooterLight;