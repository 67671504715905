import React from 'react';

import { Trans } from 'react-i18next';

import { ReactComponent as DiscordLogo } from '../../resources/images/common/discord-icon.svg';

class JoinCommunity extends React.Component {

    render() {
      return (
        <div className="join-community">
            <div><Trans>FOOTER_LABEL_JOINCOMMUNITY</Trans></div>
            <a href="https://discord.gg/VycYQHEPrF" target="_blank" rel="noreferrer">
                <DiscordLogo className="discord-icon" />
            </a>
        </div>
        );
    }
}

export default JoinCommunity;