import React from 'react';

import i18n from '../../Translations/i18n';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'

class LanguageSelector extends React.Component {

    constructor(props) {
        super(props);

        console.log("langue"+i18n.resolvedLanguage);

        this.state = {
            selectedLanguage: i18n.resolvedLanguage,
        };
    }

    handleSelectLanguage = (selectedLng) => {
        this.setState({selectedLanguage: selectedLng});
        i18n.changeLanguage(selectedLng);
    }

    render() {

        const { drop } = this.props;

        return (
            <DropdownButton
                alignRight
                title={this.state.selectedLanguage}
                onSelect={this.handleSelectLanguage}
                drop={drop}
                variant="language-selector">
                    <Dropdown.Item eventKey="en">en</Dropdown.Item>
                    <Dropdown.Item eventKey="fr">fr</Dropdown.Item>
            </DropdownButton>
        );
    }
}

export default LanguageSelector;