import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Trans } from 'react-i18next';

import { isMobileDevice } from '../../Helpers/DeviceHelpers'
import LanguageSelector from './LanguageSelector';
import JoinCommunity from './JoinCommunity';

import { ReactComponent as ArrowOutward } from '../../resources/images/home/material-symbols_arrow-outward-rounded.svg';
import { ReactComponent as KickstarterLogo } from '../../resources/images/home/kickstarter-logo.svg';

import { ReactComponent as BackIcon } from '../../resources/images/common/back-icon.svg';

class NavBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavbarMenuOpened: false,
        }
    }

    handleNavbarToggle = () => {
        this.setState({isNavbarMenuOpened: !this.state.isNavbarMenuOpened});
    }

    render() {

        let navbarClassNames = {
            navBar: "tw-navbar",
            subscribeButton: "nav-link-subscribe",
        }

        if (this.state.isNavbarMenuOpened) {
            navbarClassNames.navBar = navbarClassNames.navBar + "-opened";
            navbarClassNames.subscribeButton = "";
        }

        let showBackButton = this.props.showBackButton;
        if (showBackButton === undefined)
            showBackButton = false;

        return (
            <Navbar 
                collapseOnSelect 
                expand="lg" 
                variant="dark" 
                className={navbarClassNames.navBar} 
                fixed="top"
                onToggle={this.handleNavbarToggle}>

                {(showBackButton && !isMobileDevice()) && (<a className="back-button" href="/">
                    <BackIcon />
                </a>)}

                {this.state.isNavbarMenuOpened && (
                    <div className="tw-navbar-header">
                        <div className="tw-navbar-header-left">
                            <a className="home-button" href="/"><div></div></a>
                        </div>
                        <div className="tw-navbar-header-right">
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler-close" />
                        </div>
                    </div>
                )}
        
                {!this.state.isNavbarMenuOpened && (
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className={navbarClassNames.toggle} />
                )}
                <Navbar.Collapse id="responsive-navbar-nav">    
                    <Nav className="ml-auto">

                    </Nav>
                    <Nav className="ml-auto">
                        <Nav.Link href="/about"><Trans>NAVBAR_MENU_ABOUT</Trans></Nav.Link>
                        <Nav.Link href="/account/create" className={navbarClassNames.subscribeButton}><Trans>NAVBAR_MENU_REGISTER</Trans></Nav.Link>
                        
                        {!this.state.isNavbarMenuOpened && (<Nav.Item>
                            <LanguageSelector drop="down"  />
                        </Nav.Item>)}

                    </Nav>
                </Navbar.Collapse>

                {this.state.isNavbarMenuOpened && (
                    <div className="tw-navbar-footer">
                        <div className="tw-navbar-footer-left">
                            <LanguageSelector drop="up" />
                        </div>
                        <div className="tw-navbar-footer-right">
                            <JoinCommunity />
                        </div>
                    </div>
                )}
                
            </Navbar>
        );
    }
}

export default NavBar;