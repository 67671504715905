import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './Account.css';

import { withTranslation } from 'react-i18next';

import CreateAccount from './create/CreateAccount'
import ActivateAccount from './create/ActivateAccount'
import ResetPassword from './password-reset/ResetPassword'
import SetNewPassword from './password-reset/SetNewPassword'

import FooterLight from '../components/FooterLight'
import NavBar from '../components/NavBar'
import LanguageSelector from '../components/LanguageSelector'

import { isMobileDevice } from '../../Helpers/DeviceHelpers'

import { ReactComponent as BackIcon } from '../../resources/images/common/back-icon.svg';

function Account() {

  return (
    <div className="account">
      <header className="account-header">
        <div className="account-content">

          {!isMobileDevice() && (
            <div className="account-navbar">
              <a className="back-button" href="/">
                <BackIcon />
              </a>
              <LanguageSelector drop="down" />
            </div>)}

            {isMobileDevice() && (<NavBar />)}
          
          <div className="account-content-form">
            <Router>
              {/*
                A <Switch> looks through all its children <Route>
                elements and renders the first one whose path
                matches the current URL. Use a <Switch> any time
                you have multiple routes, but you want only one
                of them to render at a time
              */}
              <Switch>
                <Route path="/account/activate/:token">
                  <ActivateAccount />
                </Route>
                <Route path="/account/create">
                  <CreateAccount />
                </Route>
                <Route path="/account/reset-password">
                  <ResetPassword />
                </Route>
                <Route path="/account/password/:token">
                  <SetNewPassword />
                </Route>
                <Route path="/account">
                  <CreateAccount />
                </Route>
              </Switch>
            </Router>
          </div>

          <FooterLight />
        </div>
        <div className="account-background" />
      </header>
    </div>
  );
}

export default withTranslation()(Account);
