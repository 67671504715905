import React from 'react'
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';

import Form from '../../components/form/Form'

import '../../components/form/Form.css';

import { AccountService } from '../../../services/AccountService';
import handleError from '../../ErrorHelper';

const nicknameRegex = new RegExp("^[a-zA-Z0-9_-]+$");

class CreateAccount extends React.Component {

    _accountService = new AccountService();

    constructor(props) {
        super(props);

        this.state = 
        {
            errorMsg: null,
            accountCreated: false,
            isWorking: false,
        };
    }

    handleSubmit = async (fields) => {
        console.log(fields);
 
        try {
            this.setState({
                isWorking: true,
                errorMsg: '',
            });

            var sha1 = require('sha1');
            
            //var sha256 = require('js-sha256');
            //var hash = sha256.create();
            //hash.update(fields['invitationKey'].value);

            let createAcccountRequest = {
                Email: fields['email'].value,
                PasswordHash: sha1(fields['password'].value),
                Nickname: fields['nickname'].value,
                //ClosedBetaInvitationKeyHash: hash.hex(),
            };

            console.log(createAcccountRequest);

            await this._accountService.createAcccount(createAcccountRequest);

            console.log("account created with sucess");

            this.setState({accountCreated: true});

        } catch (ex) {
            this.setState({errorMsg: handleError(ex, "Failed to create account.")});
        } finally {
            this.setState({isWorking: false});
        }   
    }

    validateNickname = (value, fields) => {
        console.log(fields);

        if (!nicknameRegex.test(value)) {
            return {
                valid: false,
                errorMsg: "CREATEACCOUNTVIEW_ERROR_INVALIDNICKNAME",
            };
        }  
        
        if (value.length > 14) {
            return {
                valid: false,
                errorMsg: "CREATEACCOUNTVIEW_ERROR_NICKNAMETOOLONG",
            };
        }

        return {
            valid: true,
            errorMsg: null,
        };
    }

    validateConfirmPassword = (value, fields) => {
        console.log(fields);

        const password = fields['password'].value;

        if (password !== value) {
            return {
                valid: false,
                errorMsg: 'CREATEACCOUNTVIEW_ERROR_PASSWORDS_MUSTBEEQUALS',
            };
        }

        return {
            valid: true,
            errorMsg: null,
        };
    }

    render() {

        const { t } = this.props;

        console.debug(t('CeateAccount.EmailPlaceholder'));

        const fields = [
            /*{
                id: 'invitationKey',
                placeholder: 'Clé d\'invitation',
                type: 'text',
                options: {
                    required: true,
                },
                initialValue: '',
            },*/
            {
                id: 'email',
                placeholder: 'CREATEACCOUNTVIEW_INPUT_EMAIL',
                type: 'email',
                options: {
                    required: true,
                    email: true,
                },
                initialValue: '',
            },
            {
                id: 'nickname',
                placeholder: 'CREATEACCOUNTVIEW_INPUT_NICKNAME',
                type: 'text',
                options: {
                    required: true,
                },
                initialValue: '',
                customValidator: this.validateNickname,
            },
            {
                id: 'password',
                placeholder: 'CREATEACCOUNTVIEW_INPUT_PASSWORD',
                type: 'password',
                options: {
                    required: true,
                    password: true,
                },
                initialValue: '',
            },
            {
                id: 'confirm-password',
                placeholder: 'CREATEACCOUNTVIEW_INPUT_CONFIRMPASSWORD',
                type: 'password',
                options: {
                    required: true,
                },
                initialValue: '',
                customValidator: this.validateConfirmPassword,
            },
        ];

        /*if (process.env.NODE_ENV !== 'production') {
            {fields.map(field => {
                if (field.id === "email") {
                    field.initialValue = "florian.lenouail@gmail.com";
                } else if (field.id === "nickname") {
                    field.initialValue = "FloFlo";
                } else if (field.id === "password") {
                    field.initialValue = "FloFlo6971!";
                } else if (field.id === "confirm-password") {
                    field.initialValue = "FloFlo6971!";
                }
            })}
        }*/

        return (
            <div>
                <Helmet>
                    <title>Sign up on trollworkshop</title>
                </Helmet>
                <h1><Trans>CREATEACCOUNTVIEW_TITLE</Trans></h1>

                <div style={{marginTop:'40px'}}>
                    {!this.state.accountCreated &&
                        <div>
                            <Form 
                                fields={fields} 
                                submitText="CREATEACCOUNTVIEW_BUTTON_REGISTER"
                                onSubmit={this.handleSubmit} 
                                working={this.state.isWorking} />
                            <br/>
                            <span className='error-msg-style'>{this.state.errorMsg}</span>
                        </div>
                    }

                    {this.state.accountCreated &&
                        <span className='sucess-msg-style'>
                            <Trans>CREATEACCOUNTVIEW_LABEL_SUCCESS</Trans><br/><br/>
                            <Trans>CREATEACCOUNTVIEW_LABEL_SUCCESS_PART2</Trans>
                        </span>
                    }
                </div>
            </div>
        )
    }

}

export default withTranslation()(CreateAccount);