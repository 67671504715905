const MACOS_PLATFORM = "MacOs"
const IOS_PLATFORM = "iOS"
const WINDOWS_PLATFORM = "Windows"
const ANDROID_PLATFORM = "Android"
const LINUX_PLATFORM = "Linux"

function getOS() {
    var userAgent = window.navigator.userAgent;
    var platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
    var macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    var windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    var iosPlatforms = ["iPhone", "iPad", "iPod"];
    
    if (macosPlatforms.indexOf(platform) !== -1) {
        return MACOS_PLATFORM;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return IOS_PLATFORM;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return WINDOWS_PLATFORM;
    } else if (/Android/.test(userAgent)) {
        return ANDROID_PLATFORM;
    } else if (/Linux/.test(platform)) {
        return LINUX_PLATFORM;
    }
  
    return null;
}

export const isWindowsPlatform = () => getOS() === WINDOWS_PLATFORM;
export const isAndroidPlatform = () => getOS() === ANDROID_PLATFORM;
export const isMacOSPlatform = () => getOS() === MACOS_PLATFORM;

export const isMobileDevice = () => {
    return window.innerWidth <= 768;
}