import React, { Component } from 'react';

import { Translation } from 'react-i18next';

export class ControlledInput extends Component {
    constructor(props) {
        super(props);

        this.state = 
        { 
            input: props.input, 
        };
    }

    handleOnChange = (e) => {
        const input = e.target.value
        this.setState(() => ({ input }));

        this.props.onChangeComplete(this.props.options.id, input);
    }

    render() {
      const opt = this.props.options;
      const cn = `text__input ${!this.props.valid ? 'invalid__value' : ''}`;

      return (
        <Translation>
          {
            t => <input 
              className={cn}
              type={opt.type}
              id={opt.id}
              name={opt.name}
              placeholder={t(opt.placeholder)}
              required={opt.options.required ? true : false}
              value={this.state.input} 
              onChange={this.handleOnChange}
              onBlur={this.props.onBlur}
              onClick={this.props.onClick}
            />
          }
        </Translation>
      );
    }
}
