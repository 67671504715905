import { ServiceError } from '../services/ServiceError';
import { format } from 'react-string-format';

export default function handleError(error, prefix)
{
    if (error instanceof ServiceError && error !== null && error.errorCode < 0)
        return error.message;
    else if (error != null) {
            return format("{0}: {1}", prefix, error.message); 
    }

    return error != null
            ? format("{0}: {1}", prefix, error.message)
            : prefix;
}