import React from 'react'
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

import { Trans } from 'react-i18next';

import ClipLoader from "react-spinners/ClipLoader";

import Form from '../../components/form/Form'

import '../../components/form/Form.css';

import { AccountService } from '../../../services/AccountService';
import handleError from '../../ErrorHelper';

const INVALID_TOKEN_STATE = 0;
const VALID_TOKEN_STATE = 1;
const SUCCESS_STATE = 2;
const WORKING_STATE = 3;

class SetNewPassword extends React.Component {

    _accountService = new AccountService();

    constructor(props) {
        super(props);

        this.state = 
        {
            errorMsg: null,
            viewState: WORKING_STATE,
            isWorking: false,
        };
    }

    async componentDidMount() {
        try {
            // Check token validity
            await this._accountService.confirmResetPassword(this.props.match.params.token);

            this.setState({viewState: VALID_TOKEN_STATE});

        } catch (ex) {
            this.setState({errorMsg: handleError(ex, "Failed to check token validity.")});

            this.setState({viewState: INVALID_TOKEN_STATE});
        } 
    }

    handleSubmit = async (fields) => {
        console.log(fields);
 
        try {
            this.setState({
                isWorking: true,
                errorMsg: '',
            });

            var sha1 = require('sha1');

            await this._accountService.setNewPassword(
                this.props.match.params.token,
                sha1(fields['password'].value));

            this.setState({ viewState: SUCCESS_STATE });

        } catch (ex) {
            this.setState({errorMsg: handleError(ex, "Failed to set new password.")});
        } finally {
            this.setState({isWorking: false});
        }   
    }

    validateConfirmPassword = (value, fields) => {
        console.log(fields);

        const password = fields['password'].value;

        if (password !== value) {
            return {
                valid: false,
                errorMsg: 'SETNEWPASSWORDVIEW_ERROR_PASSWORDS_MUSTBEEQUALS',
            };
        }

        return {
            valid: true,
            errorMsg: null,
        };
    }

    renderLoader() {
        return (
            <div style={{marginTop:'20px'}}>
                <ClipLoader color={'#fff'} loading={true} size={35} />
            </div>
        )
    }

    renderInvalidToken() {
        return (
            <div className='account-info-container' style={{marginTop:'20px'}}>
                <p>
                    <Trans>SETNEWPASSWORDVIEW_ERROR_INVALIDTOKEN_PART1</Trans>
                    <a className='link' href='reset-password/'><Trans>SETNEWPASSWORDVIEW_ERROR_INVALIDTOKEN_PART2</Trans></a>.
                </p>
            </div>
        )
    }

    renderForm() {

        const fields = [
            {
                id: 'password',
                placeholder: 'SETNEWPASSWORDVIEW_INPUT_NEWPASSWORD',
                type: 'password',
                options: {
                    required: true,
                    password: true,
                },
                initialValue: '',
            },
            {
                id: 'confirm-password',
                placeholder: 'SETNEWPASSWORDVIEW_INPUT_CONFIRMPASSWORD',
                type: 'password',
                options: {
                    required: true,
                },
                initialValue: '',
                customValidator: this.validateConfirmPassword,
            },
        ];

        return (
            <div>
                <div style={{marginTop:'20px'}}>
                    {this.state.viewState === VALID_TOKEN_STATE &&
                        <div>
                            <Form 
                                fields={fields} 
                                submitText='SETNEWPASSWORDVIEW_BUTTON_VALIDATE'
                                onSubmit={this.handleSubmit} 
                                working={this.state.isWorking} />
                            <br/>
                            <span className='error-msg-style'>{this.state.errorMsg}</span>
                        </div>
                    }

                    {this.state.viewState === SUCCESS_STATE &&
                        <span className='sucess-msg-style'><Trans>SETNEWPASSWORDVIEW_LABEL_SUCCESS</Trans></span>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Définissez un nouveau mot de passe</title>
                </Helmet>
                <h1><Trans>SETNEWPASSWORDVIEW_TITLE</Trans></h1>

                {this.state.viewState === WORKING_STATE  && this.renderLoader()}
                {this.state.viewState === INVALID_TOKEN_STATE  && this.renderInvalidToken()}
                {(this.state.viewState === VALID_TOKEN_STATE || this.state.viewState === SUCCESS_STATE) && this.renderForm()}
            </div>
        )
    }

}

export default withRouter(SetNewPassword);