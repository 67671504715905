import React from 'react';
import Button from 'react-bootstrap/Button';

import { withRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

import config from '../../config.json';

import "./Home.css"

import { Trans, withTranslation } from 'react-i18next';
import { isWindowsPlatform, isAndroidPlatform, isMacOSPlatform, isMobileDevice } from '../../Helpers/DeviceHelpers'

import HeaderLeftImage from '../../resources/images/home/header-image.png';
import OpenBetaImage from '../../resources/images/home/beta.png';
import { ReactComponent as ArrowOutward } from '../../resources/images/home/material-symbols_arrow-outward-rounded.svg';
import { ReactComponent as CloseIcon } from '../../resources/images/common/icon-cross.svg';

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = 
        {
            isDownloadDialogShown: false,
            isRequirementsDialogShown: false,
        };
    }

    navigateToRegisterView = () => {
        this.props.history.push("/account/create");
    }

    showDownloadDialog = (show) => {
        this.setState({isDownloadDialogShown: show});
    }

    showRequirementsDialog = (show) => {
        this.setState({isRequirementsDialogShown: show});
    }

    downloadInstaller = () => {
        if (isWindowsPlatform()) {
            this.downloadFile("windows/", "TrollworkshopInstaller.exe" );
        } else if (isMacOSPlatform()) {
            this.downloadFile("macos/", "Trollworkshop.zip" );
        }
        /*else if (isAndroidPlatform()) {
            this.downloadFile("android/", "Trollworkshop.apk" );
        }*/

        this.showDownloadDialog(false);
    }

    downloadFile = (relativePath, fileName) => {
        // anchor link
        const element = document.createElement("a");
        element.href =  "https://" + config.BackendHostName + "/trollworkshop_client/" + relativePath + fileName;
        element.download = fileName;

        // simulate link click
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    renderDownloadDialog = () => {
        return (
            <Modal
                show={this.state.isDownloadDialogShown}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="tw-modal">
                <Modal.Body>
                    <div className="dialog-header">
                        <CloseIcon onClick={() => this.showDownloadDialog(false)} />
                    </div>
                    <p>
                        <span><Trans>HOMEVIEW_DOWNLOADDIALOG_TEXT_PART1</Trans></span> 
                        <Trans>HOMEVIEW_DOWNLOADDIALOG_TEXT_PART2</Trans>
                    </p>
                    <p>
                        <Trans>HOMEVIEW_DOWNLOADDIALOG_TEXT_PART3</Trans>
                    </p>
                    <p>
                        <Trans>HOMEVIEW_DOWNLOADDIALOG_TEXT_PART4</Trans>
                        <a href="https://discord.gg/VycYQHEPrF"><Trans>HOMEVIEW_DOWNLOADDIALOG_TEXT_PART5</Trans></a>
                        <Trans>HOMEVIEW_DOWNLOADDIALOG_TEXT_PART6</Trans>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="effective-download" onClick={this.downloadInstaller}>
                        <Trans>HOMEVIEW_DOWNLOADDIALOG_BUTTON_EFFECTIVEDOWNLOAD</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>  
        );
    }

    canDownloadInstaller = () => (isWindowsPlatform() || isAndroidPlatform() || isMacOSPlatform()) && !isMobileDevice();
    //canDownloadInstaller = () => (isWindowsPlatform()) && !isMobileDevice();

    render() {
        return (
            <div>
                <header className="tw-header">
                    <NavBar />

                    <Container fluid className="header-content">
                        <Row className="header-content-row">
                            <Col lg={true} className="header-left-container">
                                <img className="header-left-image" src={HeaderLeftImage} alt="Dig The Way Out" />
                            </Col>
                            <Col lg={true} className="header-right-container">
                                <div className="open-state">
                                    <img src={OpenBetaImage} alt="Open Beta" />
                                </div>
                                <div className="pres-container">
                                    <div className="actions-container">
                                        <div className="download-btn-container">
                                            <Button variant="download" className="action-btn" disabled={!this.canDownloadInstaller()}
                                                onClick={() => this.showDownloadDialog(true)}>
                                                <Trans>HOMEVIEW_BUTTON_DOWNLOAD</Trans>
                                            </Button>
                                            
                                            <OverlayTrigger
                                                trigger="click"
                                                rootClose 
                                                key="bottom"
                                                placement="bottom"
                                                overlay={
                                                    <Popover className="popover-requirement">
                                                        <Popover.Content>
                                                            <Trans>HOMEVIEW_REQUIREMENTSDIALOG_TEXT</Trans>
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                            >
                                                <Button variant="requirement"><Trans>HOMEVIEW_LABEL_ONLYONPC</Trans></Button>
                                            </OverlayTrigger>
                                        </div>
                                        <Button variant="register" className="action-btn"
                                            onClick={this.navigateToRegisterView}>
                                            <Trans>HOMEVIEW_BUTTON_REGISTER</Trans>
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {this.renderDownloadDialog()}

                    <Footer />
                </header>
            </div>
        );
    }
}

export default withTranslation()(withRouter(Home));