import ServiceError from './ServiceError';

import config from '../config.json';

export var API_URL_ENDPOINT = 'http://' + config.BackendHostName;

if (process.env.NODE_ENV === 'production') {
    API_URL_ENDPOINT = 'https://' + config.BackendHostName;
}

const BASE_PREFIX = "/api"

export const axiosRequestConfig = {
    responseType: "json",
    headers: {
        'Content-Type': 'application/json'
}};

export const axiosRequestConfigWithCredential = {
    responseType: "json",
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
}};

export class ProxyServiceBase {

    createRequest(route) {
        let url = API_URL_ENDPOINT + BASE_PREFIX + route;
        console.log(url);
        return url;
    }
   
    getResponseData(response, errorMssg) {
        let result;

        // 200 Ok avec content
        // 204 OK sans content
        if (response.status === 200 || response.status === 204) {
            result = response.data;
        }
        else {
            throw new ServiceError(
                errorMssg,
                response.status);
        }

        return result;
    }
}