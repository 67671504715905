import axios from 'axios';
import { handleAxiosError } from './AxiosErrorHandler';
import { ProxyServiceBase } from './ProxyServiceBase';

import { format } from 'react-string-format';

const ACCOUNT_API_ROUTE = "/account";
const CREATE_ACCOUNT_ROUTE = "/create";
const ACTIVATE_ACCOUNT_ROUTE = "/activate/{0}";
const SEND_PASSWORD_RESET_CODE_ROUTE = "/password-reset/send-code";
const CONFIRM_RESET_PASSWORD_ROUTE = "/password-reset/reset-password/{0}";
const SET_NEW_PASSWORD_ROUTE = "/password-reset/reset-password";

export class AccountService extends ProxyServiceBase {

    createRequest(route) {
        return super.createRequest(ACCOUNT_API_ROUTE + route);
    }
   
    async createAcccount(createAcccountRequest) {
        console.debug("totot")
        
        try {
            let response = await axios.post(
                this.createRequest(CREATE_ACCOUNT_ROUTE),
                createAcccountRequest);
            
            this.getResponseData(response, "Failed to create the account");
        }
        catch(ex) {
            throw handleAxiosError(ex);
        }
    }

    async activateAccount(token) {
        try {
            let response = await axios.get(
                this.createRequest(format(ACTIVATE_ACCOUNT_ROUTE, token)));
            
            this.getResponseData(response, "Failed to activate account");
        }
        catch(ex) {
            throw handleAxiosError(ex);
        }
    }

    async sendPasswordResetCode(email) {
        try {
            let response = await axios.post(
                this.createRequest(SEND_PASSWORD_RESET_CODE_ROUTE),
                {
                    Email: email
                });
            
            this.getResponseData(response, "Failed to send code to reset password");
        }
        catch(ex) {
            throw handleAxiosError(ex);
        }
    }

    async confirmResetPassword(token) {
        try {
            let response = await axios.get(
                this.createRequest(format(CONFIRM_RESET_PASSWORD_ROUTE, token)));
            
            this.getResponseData(response, "Failed to check token to reset password");
        }
        catch(ex) {
            throw handleAxiosError(ex);
        }
    }

    async setNewPassword(token, newPasswordHash) {
        try {
            let response = await axios.post(
                this.createRequest(SET_NEW_PASSWORD_ROUTE),
                {
                    CodeBase64Url: token,
                    NewPasswordHash: newPasswordHash,
                });
            
            this.getResponseData(response, "Failed to set new password");
        }
        catch(ex) {
            throw handleAxiosError(ex);
        }
    }   
}

 