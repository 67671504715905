export class ServiceError extends Error {
    
    errorCode;
    
    constructor(message, errorCode) {
        super(message);
        this.name = "BusinessError";
        this.errorCode = errorCode;
    }
}

export default ServiceError;