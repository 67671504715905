import { AxiosError } from "axios"
import ServiceError from './ServiceError';


export function handleAxiosError(ex)  {

    if ( ex instanceof AxiosError ) {
        if (ex !== null && ex.response !== undefined) {
            console.log(ex);
            // ResponseStatus: objet renvoyé par ServiceStack
            if (ex.response.data.ResponseStatus != null) {
                return new ServiceError(
                    ex.response.data.ResponseStatus.Message,
                    Number(ex.response.data.ResponseStatus.ErrorCode))
    
            } else {
                return new ServiceError(
                    ex.response.status + " " + ex.response.statusText,
                    Number(ex.response.status))
            }
        } else if (ex != null) {
    
            return new ServiceError(
                ex.message,
                999999)
        }
    }
    else {
        return new ServiceError(
            "unknown error",
            999999)
    }
}