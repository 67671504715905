import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "HOMEVIEW_BUTTON_DOWNLOAD": "Download",
      "HOMEVIEW_LABEL_ONLYONPC": "Only on PC.",
      "HOMEVIEW_BUTTON_REGISTER": "Register",
      "HOMEVIEW_LABEL_PRESENTATION_PART1": "Throughout the kickstarter campaign (from February 20 to March 3) come and play for free at ",
      "HOMEVIEW_LABEL_PRESENTATION_PART2": "The number of places is currently limited, according to the order of registration.",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART1": "Warning: ",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART2": "The game is still in beta version, it is possible that bugs occur.",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART3": "Technical support is present to allow you the best gaming experience.",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART4": "Do not hesitate to report any technical problems to us ",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART5": "via discord",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART6": "or from the game.",
      "HOMEVIEW_DOWNLOADDIALOG_BUTTON_EFFECTIVEDOWNLOAD": "I understand",
      "HOMEVIEW_REQUIREMENTSDIALOG_TEXT": "For this first version, the game is only available on Windows, in 64 bits. In the future we plan to deploy it on other platforms.",
      "FOOTER_LABEL_JOINCOMMUNITY": "Join the community",
      "FOOTER_COPYRIGHT": "Developed by Trollworkshop",
      "NAVBAR_MENU_ABOUT": "About",
      "NAVBAR_MENU_REGISTER": "Register",
      "INPUT_ERROR_REQUIREDFIELD": "This field is required.",
      "INPUT_ERROR_FIELDTOOLONG": "This field is too long.",
      "INPUT_ERROR_INVALIDEMAIL": "Email is invalid.",
      "INPUT_ERROR_INVALIDPASSWORD": "The password is invalid.",
      "FORM_PASSWORD_RULE_MINIMUM8CHARACTERS": "Minimum 8 characters",
      "FORM_PASSWORD_RULE_ONENUMBER": "1 number",
      "FORM_PASSWORD_RULE_ONECAPITALLETTER": "1 capital letter",
      "FORM_PASSWORD_RULE_ONESPECIALCHARACTER": "1 special character (!@#$%^&*-_)",
      "CREATEACCOUNTVIEW_TITLE": "Sign up on Trollworkshop",   
      "CREATEACCOUNTVIEW_LABEL_SUCCESS": "Your account has been successfully created.",
      "CREATEACCOUNTVIEW_LABEL_SUCCESS_PART2": "An email has just been sent to you with a link to activate your account.",
      "CREATEACCOUNTVIEW_INPUT_EMAIL": "Email",
      "CREATEACCOUNTVIEW_INPUT_NICKNAME": "Name in Trollworkshop",
      "CREATEACCOUNTVIEW_INPUT_PASSWORD": "Password",
      "CREATEACCOUNTVIEW_INPUT_CONFIRMPASSWORD": "Confirm your password",
      "CREATEACCOUNTVIEW_BUTTON_REGISTER": "Register",   
      "CREATEACCOUNTVIEW_ERROR_PASSWORDS_MUSTBEEQUALS": "Passwords must be equals.",
      "CREATEACCOUNTVIEW_ERROR_INVALIDNICKNAME": "The name is invalid. Allowed characters: letters, numbers, - or _.",
      "CREATEACCOUNTVIEW_ERROR_NICKNAMETOOLONG": "The name must not exceed 14 characters.",
      "ACTIVATEACCOUNTVIEW_TITLE": "Activate your account",
      "ACTIVATEACCOUNTVIEW_LABEL_SUCCESS": "Your account has been successfully activated.",     
      "ACTIVATEACCOUNTVIEW_ERROR_FAILED_TO_ACTIVATE_ACCOUNT": "Failed to activate your account, the activation link is invalid or has expired. Please contact the Trollworkshop team to activate your account.",
      "RESETPASSWORDVIEW_TITLE": "Reset your password",
      "RESETPASSWORDVIEW_INPUT_EMAIL": "Your email",
      "RESETPASSWORDVIEW_HEADER": "We will email you instructions for resetting your password.",      
      "RESETPASSWORDVIEW_BUTTON_SENDEMAIL": "Send email",
      "RESETPASSWORDVIEW_TITLE_EMAILSENT": "Email sent",
      "RESETPASSWORDVIEW_TEXT_EMAILSENT_PART1": "Instructions for resetting your password have been sent to",
      "RESETPASSWORDVIEW_TEXT_EMAILSENT_PART2": " If the message does not appear in your inbox, check your Junk Mail or Spam folders.",
      "RESETPASSWORDVIEW_TEXT_EMAILSENT_PART3": "If you no longer have access to this email account, please contact us.",
      "SETNEWPASSWORDVIEW_TITLE": "Set a new password",
      "SETNEWPASSWORDVIEW_ERROR_PASSWORDS_MUSTBEEQUALS": "Passwords must be equals.",
      "SETNEWPASSWORDVIEW_ERROR_INVALIDTOKEN_PART1": "The password reset link is invalid or has expired. Please submit a new password reset request by",
      "SETNEWPASSWORDVIEW_ERROR_INVALIDTOKEN_PART2": "click here",
      "SETNEWPASSWORDVIEW_INPUT_NEWPASSWORD": "New password",
      "SETNEWPASSWORDVIEW_INPUT_CONFIRMPASSWORD": "Confirm your password",
      "SETNEWPASSWORDVIEW_LABEL_SUCCESS": "Your password has been successfully reset.",
      "SETNEWPASSWORDVIEW_BUTTON_VALIDATE": "Validate",
      "ABOUTVIEW_LABEL_GANG1": "Architect and developer",
      "ABOUTVIEW_LABEL_GANG2": "Game designer and developer",
      "ABOUTVIEW_LABEL_GANG3": "UX / UI Designer",
      "ABOUTVIEW_TEXT_PRESENTATION_PART1": "Trollworkshop is a young French studio that develops board games in digital version. We adapt the games as faithfully as possible, in order to allow you to play with your friends even from a distance.",
      "ABOUTVIEW_TEXT_PRESENTATION_PART2": "We hope you enjoy testing this first version as much as we enjoyed developing it!"
    }
  },
  fr: {
    translation: {
      "HOMEVIEW_BUTTON_DOWNLOAD": "Télécharger",
      "HOMEVIEW_LABEL_ONLYONPC": "Uniquement sur PC.",
      "HOMEVIEW_BUTTON_REGISTER": "S'inscrire",
      "HOMEVIEW_LABEL_PRESENTATION_PART1": "Durant toute la campagne du kickstarter (du 20 février au 3 mars) venez jouer gratuitement à ",
      "HOMEVIEW_LABEL_PRESENTATION_PART2": "Le nombre de places est actuellement limité, selon l'ordre d'inscription.",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART1": "Avertissement : ",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART2": "Le jeu étant encore en version bêta, il est possible que des bugs surviennent.",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART3": "Un support technique est présent pour vous permettre la meilleure expérience de jeu.",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART4": "N'hésitez pas à nous faire remonter tous problèmes techniques ",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART5": "via le discord",
      "HOMEVIEW_DOWNLOADDIALOG_TEXT_PART6": " ou depuis le jeu.",
      "HOMEVIEW_DOWNLOADDIALOG_BUTTON_EFFECTIVEDOWNLOAD": "Je comprends",
      "HOMEVIEW_REQUIREMENTSDIALOG_TEXT": "Pour cette première version, le jeu est uniquement disponible sous Windows, en 64 bits. À l’avenir nous prévoyons de le déployer sur d’autres plateformes.",
      "FOOTER_LABEL_JOINCOMMUNITY": "Rejoindre la communauté",
      "FOOTER_COPYRIGHT": "Développé par Trollworkshop",
      "NAVBAR_MENU_ABOUT": "Qui sommes-nous ?",
      "NAVBAR_MENU_REGISTER": "S'inscrire",
      "INPUT_ERROR_REQUIREDFIELD": "Ce champ est obligatoire.",
      "INPUT_ERROR_FIELDTOOLONG": "Ce champ est trop long.",
      "INPUT_ERROR_INVALIDEMAIL": "L'E-mail invalide.",
      "INPUT_ERROR_INVALIDPASSWORD": "Le mot de passe est invalide.",
      "FORM_PASSWORD_RULE_MINIMUM8CHARACTERS": "Minimum 8 caractères",
      "FORM_PASSWORD_RULE_ONENUMBER": "1 nombre",
      "FORM_PASSWORD_RULE_ONECAPITALLETTER": "1 lettre majuscule",
      "FORM_PASSWORD_RULE_ONESPECIALCHARACTER": "1 caractère spécial (!@#$%^&*-_)",
      "CREATEACCOUNTVIEW_TITLE": "S'inscrire sur Trollworkshop",   
      "CREATEACCOUNTVIEW_LABEL_SUCCESS": "Votre compte a été créé avec succès.",
      "CREATEACCOUNTVIEW_LABEL_SUCCESS_PART2": "Un e-mail vient de vous être envoyé avec un lien qui permettra d'activer votre compte.", 
      "CREATEACCOUNTVIEW_INPUT_EMAIL": "E-mail",
      "CREATEACCOUNTVIEW_INPUT_NICKNAME": "Nom dans Trollworkshop",
      "CREATEACCOUNTVIEW_INPUT_PASSWORD": "Mot de passe",
      "CREATEACCOUNTVIEW_INPUT_CONFIRMPASSWORD": "Confirmer votre mot de passe",
      "CREATEACCOUNTVIEW_BUTTON_REGISTER": "S'inscrire",   
      "CREATEACCOUNTVIEW_ERROR_PASSWORDS_MUSTBEEQUALS": "Les mots de passe doivent être identiques.",
      "CREATEACCOUNTVIEW_ERROR_INVALIDNICKNAME": "Le nom est invalide. Caractères autorisés: lettres, chiffres, - ou _.",
      "CREATEACCOUNTVIEW_ERROR_NICKNAMETOOLONG": "Le nom ne doit pas dépasser 14 caractères.",
      "ACTIVATEACCOUNTVIEW_TITLE": "Activer votre compte",
      "ACTIVATEACCOUNTVIEW_LABEL_SUCCESS": "Votre compte a été activé avec succès.",     
      "ACTIVATEACCOUNTVIEW_ERROR_FAILED_TO_ACTIVATE_ACCOUNT": "Échec de l'activation de votre compte, le lien d'activation est invalide ou a expiré. Veuillez contacter l'équipe Trollworkshop pour activer votre compte.",
      "RESETPASSWORDVIEW_TITLE": "Réinitialisez votre mot de passe",
      "RESETPASSWORDVIEW_INPUT_EMAIL": "Votre e-mail",
      "RESETPASSWORDVIEW_HEADER": "Nous vous enverrons par e-mail des instructions pour réinitialiser votre mot de passe.",      
      "RESETPASSWORDVIEW_BUTTON_SENDEMAIL": "Envoyer un e-mail",
      "RESETPASSWORDVIEW_TITLE_EMAILSENT": "E-mail envoyé",
      "RESETPASSWORDVIEW_TEXT_EMAILSENT_PART1": "Les instructions pour réinitialiser votre mot de passe ont été envoyées à",
      "RESETPASSWORDVIEW_TEXT_EMAILSENT_PART2": " Si le message n'apparaît pas dans votre boîte de réception, vérifiez vos dossiers Courrier indésirable ou Spam.",
      "RESETPASSWORDVIEW_TEXT_EMAILSENT_PART3": "Si vous n'avez plus accès à ce compte de messagerie, veuillez nous contacter.",
      "SETNEWPASSWORDVIEW_TITLE": "Définir un nouveau mot de passe",
      "SETNEWPASSWORDVIEW_ERROR_PASSWORDS_MUSTBEEQUALS": "Les mots de passe doivent être égaux.",
      "SETNEWPASSWORDVIEW_ERROR_INVALIDTOKEN_PART1": "Le lien de réinitialisation du mot de passe n'est pas valide ou a expiré. Veuillez soumettre une nouvelle demande de réinitialisation de mot de passe avant",
      "SETNEWPASSWORDVIEW_ERROR_INVALIDTOKEN_PART2": "cliquer ici",
      "SETNEWPASSWORDVIEW_INPUT_NEWPASSWORD": "Nouveau mot de passe",
      "SETNEWPASSWORDVIEW_INPUT_CONFIRMPASSWORD": "Confirmer votre mot de passe",
      "SETNEWPASSWORDVIEW_LABEL_SUCCESS": "Votre mot de passe a été réinitialisé avec succès.",
      "SETNEWPASSWORDVIEW_BUTTON_VALIDATE": "Valider",
      "ABOUTVIEW_LABEL_GANG1": "Architecte et développeur",
      "ABOUTVIEW_LABEL_GANG2": "Game designer et développeur",
      "ABOUTVIEW_LABEL_GANG3": "UX / UI Designer",
      "ABOUTVIEW_TEXT_PRESENTATION_PART1": "Trollworkshop est un jeune studio français qui développe des jeux de plateau en version numérique. Nous adaptons les jeux de la manière la plus fidèle possible,  afin de vous permettre de jouer avec vos amis même à distance.",
      "ABOUTVIEW_TEXT_PRESENTATION_PART2": "On vous souhaite de prendre autant de plaisir à tester cette première version que nous en avons pris à la développer !"
    }
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    /*fallbackLng: 'fr',*/
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

  export default i18n;
