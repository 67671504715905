import React, { Component } from 'react';
import { ControlledInput } from '../input/Input';

import { Translation } from 'react-i18next';

import ClipLoader from "react-spinners/ClipLoader";

import NiceInputPassword from 'react-nice-input-password';
import 'react-nice-input-password/dist/react-nice-input-password.css';

//const passwordStrongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
const emailRegex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

const validate = (value, field) => {
    if (field.options.required) {
        if (!value || value === '') {
            return {
                valid: false,
                errorMsg: "INPUT_ERROR_REQUIREDFIELD",
            };
        } 
    }

    if (field.options.length) {
        if (value.length > 120)  {
            return {
                valid: false,
                errorMsg: "INPUT_ERROR_FIELDTOOLONG",
            };
        }
    }

    if (field.options.email) {
        if (!emailRegex.test(value)) {
            return {
                valid: false,
                errorMsg: "INPUT_ERROR_INVALIDEMAIL",
            };
        }    
    }

    if (field.options.password) {
        return {
            valid: field.valid,
            errorMsg: "INPUT_ERROR_INVALIDPASSWORD",
        };
    }

    return {
        valid: true,
        errorMsg: null,
    };
};

export default class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.props.fields.forEach(f => {
            this.state[f.id] = { 
                value: f.initialValue, 
                valid: true, 
                options: f.options,
                errorMsg: '',
                customValidator: f.customValidator,
                msgInfo: f.msgInfo,
            };
        });

        console.log('fields', this.props.fields);
    }

    validateField = (value, field) => {
        let validationResult = validate(value, field);

        if (!validationResult.valid)
            return validationResult;

        if (field.customValidator != null) {
            validationResult = field.customValidator(value, this.state);
        }

        return validationResult;
    }

    validateFieldWithId = (fieldId) => {
        const field = this.state[fieldId];

        return this.validateField(field.value, field);
    };

    handleOnChangeComplete = (id, value) => {
        //console.log(id, value);
        const validationResult = this.validateField(value, this.state[id]);
        this.setState(state => 
        { 
            state[id].value = value;
            state[id].valid = validationResult.valid;
            state[id].errorMsg = validationResult.errorMsg;
            return state 
        });
    }

    handlePasswordChange = (data, field) => {
        console.log(data);
        this.setState(state => 
        { 
            state[field.id].value = data.value;
            state[field.id].valid = data.isValid;
            return state 
        });
    }

    handleOnBlur = (e) => {
        // console.log(e.target.id, e.target.value);
        const { id, value } = e.target;
        const validationResult = this.validateField(value, this.state[id]);
        this.setState(state => 
        {
            state[id].valid = validationResult.valid;
            state[id].errorMsg = validationResult.errorMsg;
            return state 
        });
    }

    resetValidation = (e) => {
        const { id } = e.target;
        this.setState(state => {state[id].valid = true; return state });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // loop through all keys
        const keys = Object.keys(this.state);

        // Check if all fields are valid
        var allValid = true;

        keys.forEach((fId) => {
            const validationResult = this.validateFieldWithId(fId);
            //console.log(fId +  ':' + validationResult.valid);
            if (!validationResult.valid) {
                this.setState(state => 
                {
                    state[fId].valid = false; 
                    state[fId].errorMsg = validationResult.errorMsg;
                    return state 
                });
            }

            allValid &= validationResult.valid;
        });

        console.log("valid:" + allValid);

        if (allValid) {
            this.props.onSubmit(this.state);
        }
    }

    displayInputComponent(field) {
        if (field.options.password) {
            const cn = `text__input ${!field.valid ? 'invalid__value' : ''}`;

            return (
                <Translation>
                    {
                        t =>
                        (
                            <NiceInputPassword
                                name="passwordField"
                                value={this.state[field.id].value}
                                className={cn}
                                placeholder={t(field.placeholder)}
                                securityLevels={[
                                {
                                    descriptionLabel: t('FORM_PASSWORD_RULE_MINIMUM8CHARACTERS'),
                                    validator: /^.{8,}$/,
                                },
                                {
                                    descriptionLabel: t('FORM_PASSWORD_RULE_ONENUMBER'),
                                    validator: /.*[0-9].*/,
                                },
                                {
                                    descriptionLabel: t('FORM_PASSWORD_RULE_ONECAPITALLETTER'),
                                    validator: /.*[A-Z].*/,
                                },
                                {
                                    descriptionLabel: t('FORM_PASSWORD_RULE_ONESPECIALCHARACTER'),
                                    validator: /.*[!@#$%^&*-_].*/,
                                }]}
                                showSecurityLevelBar
                                showSecurityLevelDescription
                                onChange={data => this.handlePasswordChange(data, field)} />
                        )
                    }
                </Translation>
            );
        } else {
            return (
                <ControlledInput
                    options={field}
                    input={this.state[field.id].value}
                    valid={this.state[field.id].valid}
                    onBlur={this.handleOnBlur}
                    onClick={this.resetValidation}
                    onChangeComplete={this.handleOnChangeComplete} />
            );
        }
    }

    render() {

        const inputErrorMsgStyle = {
            fontSize:'12px', 
            paddingLeft:'10px',
            paddingTop:'10px',
            textAlign:'left',
            color:'#D0982D'
        }

        const msgInfoStyle = {
            fontSize:'14px', 
            paddingLeft:'0px',
            paddingBottom:'3px',
            textAlign:'left',
            color:'#FFFFFF',
        }

        const working = this.props.working;

        return (
            <Translation>
                {
                    t => 
                    (
                        <form noValidate>
                            {this.props.fields.map(field =>
                                <div key={field.id} className='form-row-custom'>
                                    {this.state[field.id].msgInfo &&
                                        <div style={msgInfoStyle}>
                                            <span>{t(this.state[field.id].msgInfo)}</span>
                                        </div>}
                                    {this.displayInputComponent(field)}
                                    {!this.state[field.id].valid &&
                                        <div style={inputErrorMsgStyle}>
                                            <span>{t(this.state[field.id].errorMsg)}</span>
                                        </div>
                                    }
                                </div>)
                            }

                            <div className='submit-row'>
                                {!working &&
                                    <input type='submit' value={t(this.props.submitText)} onClick={this.handleSubmit} className='form-submit' />
                                }

                                {working &&
                                    <ClipLoader color={'#fff'} loading={working} size={35} />
                                }
                            </div>
                        </form>
                    )
                }
            </Translation>  
        );
    }
}