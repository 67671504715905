import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

import { Trans, withTranslation } from 'react-i18next';

import PortraitDywo from '../../resources/images/about/portrait.png';

import "./About.css"

class About extends React.Component {
    
    render() {
      return (
        <div>
            <header className="tw-header">
                <NavBar showBackButton={true} />

                <Container fluid className="about-content">
                    <Row className="about-content-row">
                        <Col lg={true} className="about-left-container">
                            <p>
                                <Trans>ABOUTVIEW_TEXT_PRESENTATION_PART1</Trans><br/><br/>
                                <Trans>ABOUTVIEW_TEXT_PRESENTATION_PART2</Trans>
                            </p>
                        </Col>
                        <Col lg={true} className="about-right-container">
                            <div className="worker-info-container">
                                <img src={PortraitDywo} alt="Florian" />
                                <div>
                                    <span>Gothar AKA Florian</span><br/>
                                    <div className="text-gang gang1">
                                        <Trans>ABOUTVIEW_LABEL_GANG1</Trans>
                                    </div>
                                </div>
                            </div>
                            <div className="worker-info-container">
                                <img src={PortraitDywo} alt="Damien" />
                                <div>
                                    <span>Aoldarion AKA Damien</span><br/>
                                    <div className="text-gang gang2">
                                        <Trans>ABOUTVIEW_LABEL_GANG2</Trans>
                                    </div>
                                </div>
                            </div>
                            <div className="worker-info-container">
                                <img src={PortraitDywo} alt="Thomas" />
                                <div>
                                    <span>Timmy AKA Thomas</span><br/>
                                    <div className="text-gang gang3">
                                        <Trans>ABOUTVIEW_LABEL_GANG3</Trans>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Footer />
            </header>
        </div>
        );
    }
}

export default withTranslation()(About);