import React from 'react'
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

import ClipLoader from "react-spinners/ClipLoader";

import { Trans } from 'react-i18next';

import { AccountService } from '../../../services/AccountService';
import handleError from '../../ErrorHelper';

const FAILED_STATE = 0;
const SUCCESS_STATE = 1;
const WORKING_STATE = 2;

class ActivateAccount extends React.Component {

    _accountService = new AccountService();

    constructor(props) {
        super(props);

        this.state = 
        {
            errorMsg: null,
            viewState: WORKING_STATE,
            isWorking: false,
        };
    }

    async componentDidMount() {
        try {
            // Check token validity
            console.log("token: " + this.props.match.params.token);
            await this._accountService.activateAccount(this.props.match.params.token);

            this.setState({viewState: SUCCESS_STATE});

        } catch (ex) {
            this.setState({errorMsg: handleError(ex, "Failed to activate account.")});

            this.setState({viewState: FAILED_STATE});
        } 
    }

    renderLoader() {
        return (
            <div style={{marginTop:'20px'}}>
                <ClipLoader color={'#fff'} loading={true} size={35} />
            </div>
        )
    }

    renderFailed() {
        return (
            <div className='account-info-container' style={{marginTop:'20px'}}>
                <p>
                    <Trans>ACTIVATEACCOUNTVIEW_ERROR_FAILED_TO_ACTIVATE_ACCOUNT</Trans>
                </p>
            </div>
        )
    }

    renderSuccess() {
        return (
            <div className='account-info-container' style={{marginTop:'20px'}}>
                <p>
                    <Trans>ACTIVATEACCOUNTVIEW_LABEL_SUCCESS</Trans>
                </p>
            </div>
        )
    }

    render() {
        return (
            <div className='account-container'>
                <Helmet>
                    <title>Activation de votre compte</title>
                </Helmet>
                <h1><Trans>ACTIVATEACCOUNTVIEW_TITLE</Trans></h1>

                {this.state.viewState === WORKING_STATE  && this.renderLoader()}
                {this.state.viewState === FAILED_STATE  && this.renderFailed()}
                {(this.state.viewState === SUCCESS_STATE) && this.renderSuccess()}
            </div>
        )
    }
}

export default withRouter(ActivateAccount);